// Buttons + Forms
//
// scss-docs-start buttons-forms
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:    .375rem !default;
$input-btn-padding-x:    .75rem !default;
$input-btn-font-family:  null !default;
$input-btn-font-size:    $font-size-base !default;
$input-btn-line-height:  $line-height-base !default;

$input-btn-focus-width:       .2rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:    .25rem !default;
$input-btn-padding-x-sm:    .5rem !default;
$input-btn-font-size-sm:    $font-size-sm !default;
$input-btn-line-height-sm:  $line-height-sm !default;

$input-btn-padding-y-lg:    .5rem !default;
$input-btn-padding-x-lg:    1rem !default;
$input-btn-font-size-lg:    $font-size-lg !default;
$input-btn-line-height-lg:  $line-height-lg !default;

$input-btn-border-width:  $border-width !default;
// scss-docs-end buttons-forms


// Buttons
//
// scss-docs-start buttons
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:    $input-btn-padding-y !default;
$btn-padding-x:    $input-btn-padding-x !default;
$btn-font-family:  $input-btn-font-family !default;
$btn-font-size:    $input-btn-font-size !default;
$btn-line-height:  $input-btn-line-height !default;
$btn-white-space:  null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:    $input-btn-padding-y-sm !default;
$btn-padding-x-sm:    $input-btn-padding-x-sm !default;
$btn-font-size-sm:    $input-btn-font-size-sm !default;
$btn-line-height-sm:  $input-btn-line-height-sm !default;

$btn-padding-y-lg:    $input-btn-padding-y-lg !default;
$btn-padding-x-lg:    $input-btn-padding-x-lg !default;
$btn-font-size-lg:    $input-btn-font-size-lg !default;
$btn-line-height-lg:  $input-btn-line-height-lg !default;

$btn-border-width:  $input-btn-border-width !default;

$btn-font-weight:        $font-weight-normal !default;
$btn-box-shadow:         inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:        $input-btn-focus-width !default;
$btn-focus-box-shadow:   $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:   .65 !default;
$btn-active-box-shadow:  inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:  $gray-600 !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:     $border-radius !default;
$btn-border-radius-lg:  $border-radius-lg !default;
$btn-border-radius-sm:  $border-radius-sm !default;

$btn-transition:  color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// scss-docs-end buttons

// Forms

// scss-docs-start forms
$label-margin-bottom:  .5rem !default;

$input-padding-y:    $input-btn-padding-y !default;
$input-padding-x:    $input-btn-padding-x !default;
$input-font-family:  $input-btn-font-family !default;
$input-font-size:    $input-btn-font-size !default;
$input-font-weight:  $font-weight-base !default;
$input-line-height:  $input-btn-line-height !default;

$input-padding-y-sm:    $input-btn-padding-y-sm !default;
$input-padding-x-sm:    $input-btn-padding-x-sm !default;
$input-font-size-sm:    $input-btn-font-size-sm !default;
$input-line-height-sm:  $input-btn-line-height-sm !default;

$input-padding-y-lg:    $input-btn-padding-y-lg !default;
$input-padding-x-lg:    $input-btn-padding-x-lg !default;
$input-font-size-lg:    $input-btn-font-size-lg !default;
$input-line-height-lg:  $input-btn-line-height-lg !default;

$input-bg:           $white !default;
$input-disabled-bg:  $gray-200 !default;

$input-color:         $gray-700 !default;
$input-border-color:  $gray-200 !default;
$input-border-width:  $input-btn-border-width !default;
$input-box-shadow:    inset 0 1px 1px rgba($black, .075) !default;

$input-border-radius:     $border-radius !default;
$input-border-radius-lg:  $border-radius-lg !default;
$input-border-radius-sm:  $border-radius-sm !default;

$input-focus-bg:            $input-bg !default;
$input-focus-border-color:  lighten($component-active-bg, 25%) !default;
$input-focus-color:         $input-color !default;
$input-focus-width:         $input-btn-focus-width !default;
$input-focus-box-shadow:    $input-btn-focus-box-shadow !default;

$input-placeholder-color:  $gray-600 !default;
$input-plaintext-color:    $body-color !default;

$input-height-border:  $input-border-width * 2 !default;

$input-height-inner:          add($input-line-height * 1em, $input-padding-y * 2) !default;
$input-height-inner-half:     add($input-line-height * .5em, $input-padding-y) !default;
$input-height-inner-quarter:  add($input-line-height * .25em, $input-padding-y / 2) !default;

$input-height:     add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
$input-height-sm:  add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:  add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

$input-transition:  border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$form-text-margin-top:  .25rem !default;

$form-check-input-gutter:    1.25rem !default;
$form-check-input-margin-y:  .3rem !default;
$form-check-input-margin-x:  .25rem !default;

$form-check-inline-margin-x:        .75rem !default;
$form-check-inline-input-margin-x:  .3125rem !default;

$form-grid-gutter-width:    10px !default;
$form-group-margin-bottom:  1rem !default;

$input-group-addon-color:         $input-color !default;
$input-group-addon-bg:            $gray-100 !default;
$input-group-addon-border-color:  $input-border-color !default;

$select-option-bg: inherit !default;
// scss-docs-end forms

$form-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-theme-map: map-merge(
  (
    default: (
      "input-bg":                        $input-bg,
      "input-disabled-bg":               $input-disabled-bg,
      "input-color":                     $input-color,
      "input-border-color":              $input-border-color,
      "input-focus-bg":                  $input-focus-bg,
      "input-focus-border-color":        $input-focus-border-color,
      "input-focus-color":               $input-focus-color,
      "input-placeholder-color":         $input-placeholder-color,
      "input-plaintext-color":           $input-plaintext-color,
      "input-group-addon-color":         $input-group-addon-color,
      "input-group-addon-bg":            $input-group-addon-bg,
      "input-group-addon-border-color":  $input-group-addon-border-color,
      "select-option-bg":                $select-option-bg
    )
  ),
  $form-theme-map
);

// Form validation

$form-feedback-margin-top:     $form-text-margin-top !default;
$form-feedback-font-size:      $small-font-size !default;
$form-feedback-valid-color:    theme-color("success") !default;
$form-feedback-invalid-color:  theme-color("danger") !default;

$form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
$form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    "valid": (
      "color":  $form-feedback-valid-color,
      "icon":   $form-feedback-icon-valid
    ),
    "invalid": (
      "color":  $form-feedback-invalid-color,
      "icon":   $form-feedback-icon-invalid
    ),
  ),
  $form-validation-states
);
