// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
// scss-docs-start table
$table-cell-padding:      .75rem !default;
$table-cell-padding-sm:   .3rem !default;

$table-border-width:  $border-width !default;

$table-striped-order:  odd !default;

$table-caption-color:  $text-muted !default;

$table-bg-level:     -9 !default;
$table-border-level: -6 !default;
// scss-docs-end table

// Default Theme
// scss-docs-start table-default-theme
$table-color:         $body-color !default;
$table-bg:            null !default;
$table-accent-bg:     rgba($black, .05) !default;
$table-hover-color:   $table-color !default;
$table-hover-bg:      rgba($black, .075) !default;
$table-active-bg:     $gray-200 !default;
$table-border-color:  $border-color !default;
$table-head-bg:       $gray-200 !default;
$table-head-color:    $gray-700 !default;

$table-dark-color:         $white !default;
$table-dark-bg:            $gray-800 !default;
$table-dark-accent-bg:     rgba($white, .05) !default;
$table-dark-hover-color:   $table-dark-color !default;
$table-dark-hover-bg:      rgba($white, .075) !default;
$table-dark-border-color:  lighten($table-dark-bg, 7.5%) !default;
// scss-docs-end table-default-theme

$table-theme-map: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$table-theme-map: map-merge(
  (
    default: (
      "table-color":              $table-color,
      "table-bg":                 $table-bg,
      "table-accent-bg":          $table-accent-bg,
      "table-hover-color":        $table-hover-color,
      "table-hover-bg":           $table-hover-bg,
      "table-active-bg":          $table-active-bg,
      "table-border-color":       $table-border-color,
      "table-head-bg":            $table-head-bg,
      "table-head-color":         $table-head-color,
      "table-dark-color":         $table-dark-color,
      "table-dark-bg":            $table-dark-bg,
      "table-dark-accent-bg":     $table-dark-accent-bg,
      "table-dark-hover-color":   $table-dark-hover-color,
      "table-dark-hover-bg":      $table-dark-hover-bg,
      "table-dark-border-color":  $table-dark-border-color
    )
  ),
  $table-theme-map
);
